<template>
    <div
        class="container"
        :style="{
            'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
            'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
        }"
    >
        <div class="d-flex flex-row align-items-center mb-3" style="gap: 0px">
            <BackButton />
            <h1 class="m-0 text-light">{{ t('pages.more.contactSupport') }}</h1>
        </div>
        <ul class="list-group shadow-sm">
            <a
                :href="'https://wa.me/' + settings.whatsapp"
                class="list-group-item tap-react d-flex flex-row justify-content-between align-items-center"
            >
                <div>
                    <div style="font-size: 1.08rem" class="fw-medium">
                        <i
                            style="font-size: 1.2rem"
                            class="fa-brands fa-whatsapp me-1"
                        ></i>
                        WhatsApp
                    </div>
                    <span class="text-muted">
                        {{ settings.whatsapp }}
                    </span>
                </div>
                <i class="fa fa-chevron-right text-muted"></i>
            </a>
            <a
                :href="'tel:' + settings.whatsapp"
                class="list-group-item tap-react d-flex flex-row justify-content-between align-items-center"
            >
                <div>
                    <div style="font-size: 1.08rem" class="fw-medium">
                        <i class="fa fa-phone me-1"></i>
                        {{ t('pages.more.contactSupportPage.phone') }}
                    </div>
                    <span class="text-muted">
                        {{ settings.phone }}
                    </span>
                </div>
                <i class="fa fa-chevron-right text-muted"></i>
            </a>
            <a
                :href="'mailto:' + settings.email"
                class="list-group-item tap-react d-flex flex-row justify-content-between align-items-center"
            >
                <div>
                    <div style="font-size: 1.08rem" class="fw-medium">
                        <i class="fa fa-envelope me-1"></i>
                        E-mail
                    </div>
                    <span class="text-muted">
                        {{ settings.email }}
                    </span>
                </div>
                <i class="fa fa-chevron-right text-muted"></i>
            </a>
            <a
                target="_blank"
                :href="settings.website"
                class="list-group-item tap-react d-flex flex-row justify-content-between align-items-center"
            >
                <div>
                    <div style="font-size: 1.08rem" class="fw-medium">
                        {{ t('pages.more.contactSupportPage.website') }}
                    </div>
                    <span class="text-muted">
                        {{ settings.website }}
                    </span>
                </div>
                <i class="fa fa-chevron-right text-muted"></i>
            </a>
        </ul>
    </div>
</template>

<script setup>
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';
import BackButton from '@/components/BackButton';
import { storeToRefs } from 'pinia';
import { useRemoteSettingsStore } from '@/stores/remote-settings-store';
const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');
const { t } = useI18n();

const remoteSettingsStore = useRemoteSettingsStore();
const { settings } = storeToRefs(remoteSettingsStore);
</script>
